import { getConstant } from 'commons/js/constants'

export function buildFirebaseUrl (mobileUrl, webUrl = null) {
    const url = new URL(getConstant('FIREBASE_APPS_URI'))

    url.searchParams.append('link', mobileUrl)
    url.searchParams.append('dfl', webUrl || window.location.hostname)
    url.searchParams.append('apn', getConstant('ANDROID_APPLICATION_APN'))
    url.searchParams.append('isi', getConstant('IOS_APPLICATION_ISI'))
    url.searchParams.append('ibi', getConstant('IOS_APPLICATION_IBI'))
    return url.toString()
}
